import { Pipe, PipeTransform } from '@angular/core';
import { RelativePdfFontSizePipe } from './relative-pdf-font-size.pipe';

@Pipe({
  name: 'relativePdfLineHeight',
})
export class RelativePdfLineHeightPipe
  extends RelativePdfFontSizePipe
  implements PipeTransform
{
  transform(fontSize: number, pdfElementHeight: number): number {
    this._pdfElementHeight = pdfElementHeight;
    return this.calcLineHeightPx(fontSize);
  }

  calcLineHeightPx(fontSize: number): number {
    return this.calcFontSizePx(fontSize) * 1.1;
  }
}
