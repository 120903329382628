<ng-template #stickyNotePopup>
    <div class="container">
        <div class="modal-header">
            <h5 class="title mb-0">{{'DOWNLOAD-PDF.Download Document' | translate}}</h5>
            <button type="button"
                class="close"
                aria-label="Close"
                (click)="dismissedPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body p-4">
        <div *ngIf="fileDetails?.length">
            <h5 class="text-center mb-4 margin-border">
                {{'DOWNLOAD-PDF.INCLUDE-FILES' | translate}}
            </h5>
            <div class="w-100 mb-2">
                <div class="btn-group btn-group-toggle btn-group-lg w-100"
                    id="radio-user-type"
                    ngbRadioGroup
                    name="userTypeRadio">
                    <label ngbButtonLabel
                        [ngClass]="{'selected': !splitPDF}"
                        (click)="splitPDF = false"
                        [style.--c]="themeList?.header_table_color"
                        class="btn btn-theme">
                        <svg-icon src="assets/images/icons/checkmark-outline.svg"
                            [svgStyle]="{ 'height.px': 15, 'width.px': 15 }">
                        </svg-icon>
                        <span class="text-button">
                            {{ 'MEMO-DOWNLOAD-MULTIPLE.MERGE-FILE' | translate }}
                        </span>
                    </label>
                    <label ngbButtonLabel
                        [ngClass]="{'selected': splitPDF}"
                        (click)="splitPDF = true"
                        [style.--c]="themeList?.header_table_color"
                        class="btn btn-theme">
                        <svg-icon src="assets/images/icons/checkmark-outline.svg"
                            [svgStyle]="{ 'height.px': 15, 'width.px': 15 }">
                        </svg-icon>
                        <span class="text-button">
                            {{ 'MEMO-DOWNLOAD-MULTIPLE.SPLIT-FILE' | translate }}
                        </span>
                    </label>
                </div>
            </div>
        </div>

        <div class="main-content">
            <div class="check-all"
                (click)="$event.stopPropagation(); toggleStickyOnPdf()">
                <input type="checkbox"
                    class="mr-3"
                    [style.--c]="themeList?.header_table_color"
                    readonly
                    [checked]="putStickyOnPdf">
                {{'DOWNLOAD-PDF.ENABLE-STICKIES' | translate}}
                <div class="enable-box"
                    [ngClass]="{'selected': putStickyOnPdf}">
                    <div class="small-dot"></div>
                    {{ (putStickyOnPdf ? 'DOWNLOAD-PDF.ENABLED' : 'DOWNLOAD-PDF.DISABLED') | translate }}
                </div>
            </div>

            <div class="row my-3 px-3 no-gutters pointer"
                (click)="selectAllDocs()">
                <input type="checkbox"
                    [style.--c]="themeList?.header_table_color"
                    class="mr-3"
                    readonly
                    [checked]="selectAll">
                <span>{{ 'MEMO-DOWNLOAD-MULTIPLE.SELECT-ALL-ITEMS-BELOW' | translate }}</span>
            </div>
            <ng-container *ngFor="let eachTitle of documentList;">
                <div class="each-row">
                    <div class="col-11 row no-gutters px-2"
                        (click)="$event.stopPropagation(); toggleCheck(eachTitle)">
                        <input type="checkbox"
                            [style.--c]="themeList?.header_table_color"
                            class="mr-3"
                            readonly
                            [checked]="checkBoxes[eachTitle]">
                        <span>{{ translations[eachTitle] | translate }}</span>
                    </div>
                    <div *ngIf="fileDetails?.length && eachTitle ==='originalDocument'"
                        class="col-1"
                        (click)="toggleExpand(eachTitle)">
                        <i class="fas"
                            [ngClass]="openRelatedDocs[eachTitle] ? 'fa-chevron-up': 'fa-chevron-down' "></i>
                    </div>
                </div>
                <ng-container *ngIf="canExpand(eachTitle) && openRelatedDocs[eachTitle]">
                    <div class="px-3 mx-3 py-1"
                        *ngIf=" eachTitle ==='originalDocument'">
                        <div class="d-flex align-items-center mb-3"
                            *ngFor="let item of fileDetails ; let index = index">
                            <input type="checkbox"
                                [style.--c]="themeList?.header_table_color"
                                class="mr-3"
                                readonly
                                id="detail.file_key"
                                name="detail.file_key"
                                (change)="itemSelectAll(item,eachTitle,index)"
                                [checked]="item.checked">
                            <label class="m-0"
                                for="detail.file_key">
                                {{ item.file_name }}
                            </label>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button"
            class="btn btn-outline w-auto mr-2"
            (click)="dismissedPopup()">
            {{ 'MEMOS.CANCEL' | translate }}
        </button>
        <button type="button"
            class="btn"
            appSetTheme
            (click)="downloadPDF()"
            [disabled]="!isSelectCheckBox()"
            [ladda]="isLoading">
            {{ 'MEMOS.DOWNLOAD' | translate }}
        </button>
    </div>
</ng-template>