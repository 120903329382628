import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import {
  CreateStickyNoteIdentity,
  StickyNote,
  UpdateStickyNoteIdentity,
} from 'src/app/modules/memos/components/upload-memos/form-inputs/form-inputs.model';

@Injectable({
  providedIn: 'root',
})
export class StickyNoteService {
  $stickyNotes: BehaviorSubject<StickyNote[]> = new BehaviorSubject<
    StickyNote[]
  >([]);
  $selectedStickyNoteID: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  $showStickyNoteLayer: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  constructor(private http: ApiService) {}

  createStickNote(data: CreateStickyNoteIdentity) {
    return this.http.post('/api/sticky-notes/', data);
  }

  getStickyNotes(
    memoId: number,
    params?: { [key in string]: string },
  ): Observable<StickyNote[]> {
    return this.http.get(`/api/sticky-notes/?memo=${memoId}`, params);
  }

  updateStickyNote(id: number, data: UpdateStickyNoteIdentity) {
    return this.http.patch(`/api/sticky-notes/${id}/`, data);
  }

  deleteStickyNote(id: number) {
    return this.http.delete(`/api/sticky-notes/${id}/`);
  }
}
