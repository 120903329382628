import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativePdfFontSize',
})
export class RelativePdfFontSizePipe implements PipeTransform {
  protected _pdfElementHeight: number;

  transform(fontSize: number, pdfElementHeight: number): number {
    this._pdfElementHeight = pdfElementHeight;
    return this.calcFontSizePx(fontSize);
  }

  calcFontSizePx(size: number): number {
    return Math.round((size / 100) * this._pdfElementHeight);
  }
}
