import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemoDetail } from 'src/app/modules/memos/model/memo.model';
import { featureFlag } from 'src/environments/environment';

@Component({
  selector: 'app-custom-download-pdf',
  templateUrl: './custom-download-pdf.component.html',
  styleUrls: ['./custom-download-pdf.component.scss'],
})
export class CustomDownloadPdfComponent implements OnChanges {
  _memo: MemoDetail;
  @Input() set memo(val) {
    this._memo = val;
    const upload_file_details =
      this._memo?.upload?.multi_upload_pdf_detail
        ?.upload_file_details || [];
    this.fileDetails = upload_file_details.map((detail) => {
      return { ...detail, checked: this.selectedAll };
    });

    if (this._memo?.memo_number) {
      this.memoNumberFileName = this._memo.memo_number.replace(
        /\./gi,
        '-',
      );
    }

    this.mergedAll = {
      url: this._memo?.signed_document,
      name: this._memo?.signed_document.split('/').pop(),
    };

    if (featureFlag.ddoc) {
      this.ddocEnable = this._memo.is_ddoc;
    }
  }

  @Input() showPopup = false;
  @Input() memoID: number | null = null;
  @Input() memoNumber = 'download';
  @ViewChild('stickyNotePopup') stickyNotePopup: NgbModalRef;
  @Output() dismiss = new EventEmitter();
  modal: NgbModalRef;
  ddocEnable = false;
  memoNumberFileName = 'memos';
  mergedAll: any;
  themeList: ThemeList;
  putStickyOnPdf = false;
  selectAll = false;
  selectedAll = false;
  fileDetails: any;
  checkBoxes = {
    originalDocument: true,
    allStickies: true,
  };

  translations = {
    originalDocument: 'DOWNLOAD-PDF.ORIGINAL-DOCUMENT',
    allStickies: 'DOWNLOAD-PDF.NOTES',
  };

  openRelatedDocs = {
    originalDocument: true,
  };
  splitPDF = false;
  isLoading = false;
  list = [];
  selectItem = [];

  constructor(
    private alert: AlertService,
    private modalService: NgbModal,
    private themeService: ThemeService,
    private memoService: MemoService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      simpleChanges.showPopup &&
      simpleChanges.showPopup.currentValue
    ) {
      this.showDownLoadPopup();
      this.checkBoxes = {
        originalDocument: true,
        allStickies: true,
      };
      this.getRelatedData();
      this.selectAllDocs();
    }
  }

  toggleStickyOnPdf(): void {
    this.putStickyOnPdf = !this.putStickyOnPdf;
  }

  toggleCheck(title: string): void {
    this.checkBoxes[title] = !this.checkBoxes[title];
    if (title === 'originalDocument') {
      this.fileDetails?.forEach((detail) => {
        detail.checked = this.checkBoxes[title] === true;
      });
    }
    this.checkSelectedAll();
  }

  itemSelectAll(item: any, eachTitle: any) {
    this.checkBoxes.originalDocument = true;
    item.checked = !item.checked;
    this.checkBoxes[eachTitle] = this.isItemCheckAll() ? true : false;
    if (!this.checkBoxes[eachTitle]) {
      this.selectAll = false;
    }
  }
  isItemCheckAll(): boolean {
    this.checkSelectedAll();
    return this.fileDetails.every((item) => item.checked === true);
  }

  isSelectCheckBox(): boolean {
    if (this.checkBoxes.originalDocument) {
      this.fileDetails?.forEach((detail) => {
        detail.checked = true;
      });
    }

    if (this.fileDetails?.length === 0) {
      return this.checkBoxes?.originalDocument ?? false;
    }
    return this.fileDetails?.some((item) => item.checked === true);
  }

  checkSelectedAll(): void {
    this.selectAll = Object.values(this.checkBoxes).every(
      (each) => each === true,
    );
  }

  selectAllDocs(): void {
    this.selectAll = !this.selectAll;
    Object.keys(this.checkBoxes).forEach((eachTitle) => {
      this.checkBoxes[eachTitle] = this.selectAll;
    });

    this.fileDetails?.forEach((detail) => {
      detail.checked = this.selectAll === true;
    });
  }

  getRelatedData(): void {
    if (!this.memoID) return;
    Object.keys(this.checkBoxes).forEach((eachTitle) => {
      this.checkBoxes[eachTitle] = this.selectAll;
    });
  }

  get documentList(): string[] {
    return Object.keys(this.checkBoxes);
  }
  showDownLoadPopup(): void {
    this.modal = this.modalService.open(this.stickyNotePopup, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  dismissedPopup(): void {
    this.selectAll = false;
    this.modal?.close();
    this.dismiss.emit();
  }

  downloadPDF() {
    const selectItemPDF = this.fileDetails
      ?.filter((item) => item.checked === true)
      .map((v) => v.id);
    const dataForBE: any = {
      include_original_memo: this.checkBoxes.originalDocument,
      include_sticky_note_on_pdf: this.putStickyOnPdf,
      include_sticky_note: this.checkBoxes.allStickies,
      include_upload_file_ids: selectItemPDF,
      split_pdf: this.splitPDF,
    };
    this.isLoading = true;
    this.memoService
      .customDownloadPDf(this.memoID?.toString(), dataForBE)
      .subscribe({
        next: (blob) => {
          const fieldName = `${this.memoNumber}${
            this.splitPDF ? '.zip' : '.pdf'
          }`;
          this.downloadFileFromBlob(blob, fieldName);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.alert.error(
            error?.statusText ||
              error?.message ||
              error?.status ||
              'Error',
          );
        },
      });
  }

  canExpand(title: string): boolean {
    return Object.keys(this.openRelatedDocs).includes(title);
  }

  toggleExpand(title: string): void {
    this.openRelatedDocs[title] = !this.openRelatedDocs[title];
  }

  downloadFileFromBlob(
    blob: Blob,
    fileName = 'downloaded.pdf',
  ): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
