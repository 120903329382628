<ng-template #confirmationModal
    let-c="close">
  <app-modal-confirmation [title]="contentText()"
      [disableBtn]="enableReason && !reason"
      (closeModal)="close()"
      (confirmModal)="submit()">
    <ng-template appCustomModalBody>
      <div class="modal-body f-18"
          [ngClass]="actionType ==='approve' ? 'p-5' : 'py-4'">
        <div class="text-center"
            *ngIf="!enableReason">
          <span>{{"APPROVAL.APPROVE-NO-REQUIRED"|translate}}</span>
        </div>
        <div *ngIf="enableReason">
          <span>{{ reasonLabel | translate }}</span>
          <div class="form-group mt-2">
            <textarea class="form-control"
                fz-elastic
                [(ngModel)]="reason"
                (ngModelChange)="onReasonChange()">
            </textarea>
          </div>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>